// NoInternet.js
import React from 'react';

const NoInternet = () => {
  return (
    <div style={styles.container}>
      <img
        src={require('../assets/img/no-internet.jpg')}
        alt="No Internet"
        style={styles.image}
      />
      <h1 style={styles.heading}>No Internet Connection</h1>
      <p style={styles.text}>Please check your network and try again.</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '10%',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  image: {
    width: '300px',
    height: 'auto',
    marginBottom: '20px',
  },
  heading: {
    fontSize: '2.5rem',
    color: '#333',
    marginBottom: '10px',
  },
  text: {
    fontSize: '1.2rem',
    color: '#666',
  },
};

export default NoInternet;
