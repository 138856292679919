import { createSlice } from "@reduxjs/toolkit";
import { getProfileApi } from "../../apis/Profile/ProfileApi";

// import { dispatch } from "store";

const initialState = {
  user: null,
  isLoggedInAsStudent: false,
  student: null,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setStudent(state, action) {
      state.student = action.payload;
    },
    setIsLoggedInAsStudent(state, action) {
      state.isLoggedInAsStudent = action.payload;
    },
    emptyUser(state) {
      state.user = null;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});

export default user.reducer;

export const {
  setUser,
  setStudent,
  setIsLoggedInAsStudent,
  emptyStudent,
  emptyUser,
  setNotifications,
} = user.actions;

export const fetchProfile = () => async (dispatch) => {
  try {
    const response = await getProfileApi();
    const user = response.data.data ?? null;
    dispatch(setUser(user));
  } catch (error) {
    console.log("Failed to fetch user");
  }
};

export const selectUser = (state) => state.user.user;
export const selectStudent = (state) => state.user.student;
export const selectIsLoggedInAsStudent = (state) =>
  state.user.isLoggedInAsStudent;

export const selectNotifications = (state) => state.user.notifications;
