import { postMethod, postFormDataMethod } from "../ApiConfig";
// import { API_URL } from "../../config/config"

export const getProfileApi = async (data) => {
  return postMethod(`/profile/get-user-profile-details`, data);
};

export const updateProfileApi = async (data) => {
  return postMethod(`/profile/update-user-profile-detail`, data);
};

export const updateProfileImageApi = async (data) => {
  return postFormDataMethod(`/profile/update-profile-image`, data);
};

export const logoutUserApi = async (data) => {
  return postMethod(`/profile/logout`, data);
};
