import { createContext, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { isValidToken, setSession } from "../utils/token";
import { verifyOtpApi, getProfileApi } from "../apis/Auth/AuthApi";

const initialState = {
  isAuthenticated: false,

  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGNIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      isInitialized: true,
    };
  },
  SIGNOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isInitialized: true,
  }),
  SIGNUP: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      isInitialized: true,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const signUp = async (payload) => {

    const response = await verifyOtpApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        throw error;
      };
      customError(response?.data?.message);
    }

    setSession(token);
    dispatch({
      type: "SIGNUP",
      payload: {
        user: data,
      },
    });

    return response?.data;
  };

  const signIn = async (payload) => {
    const response = await verifyOtpApi(payload);
    const { token, data } = response?.data;
    if (!data || !token) {
      const customError = (message = "") => {
        const error = new Error();
        error.name = "CustomError";
        error.message = message;
        error.redirectTo = response?.data?.redirectTo;
        throw error;
      };
      customError(response?.data?.message);
    }

    setSession(token);

    dispatch({
      type: "SIGNIN",
      payload: {
        data,
      },
    });
  };

  const signOut = () => {
    setSession(null);
    dispatch({ type: "SIGNOUT" });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const response = await getProfileApi();
          const user = response?.data?.data;
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              isInitialized: true,
              user,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            isStudentAuthenticated: false,
            user: null,
            student: null,
          },
        });
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        signUp,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
