import "./App.css";
import React, { useEffect, useState } from "react";
import RouterComponent from "./routes/Routes";
import Notistack from "./Components/third-party/Notistack";
import Loader from "./Components/loader/Loader";
import NoInternet from "./Components/NoInternet";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <Notistack>
          <Loader />
          <RouterComponent />
        </Notistack>
      ) : (
        <NoInternet />
      )}
    </>
  );
}

export default App;
